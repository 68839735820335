import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../../assets/logo.png";
import { Call } from "@mui/icons-material";

export const TapetesSaoCarlos = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [country, setCountry] = useState("br");
  const [callType, setCallType] = useState("clientes_inativos");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fromNumber = "15489185087";
    const baseUrl =
      "https://initiateretellcall-655075094783.us-central1.run.app";

    let url = "";

    switch (callType) {
      case "clientes_inativos":
        url = `${baseUrl}?nome_cliente=${userFirstName}&to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=agent_0624757993de8441eccb3864b4`;
        break;
      case "convite_feira_abimad":
        url = `${baseUrl}?nome_cliente=${userFirstName}&to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=agent_f1d2e630d982f33c7677f887a0`;
        break;
      case "atendimento_inbound":
        url = `${baseUrl}?to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=agent_0f39ea5d1286b4da6452699ef5`;
        break;
      default:
        break;
    }

    console.log(`Starting call with URL ${url}`);

    await axios
      .request(url, {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => console.log(res));
  };

  return (
    <div className="full-page-background">
      <Grid item xs={12} md={7}>
        <Container className="App" maxWidth="sm">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Tapetes São Carlos DEMO</h1>
          <h3>
            Website para testes da DEMO criada pela JasperVOCAL para a empresa
            Tapetes São Carlos
          </h3>
          <form onSubmit={handleSubmit}>
            <Select
              value={callType}
              onChange={(e) => setCallType(e.target.value)}
              required
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
              }}
            >
              <MenuItem value="clientes_inativos">
                Clientes Inativos (2 meses)
              </MenuItem>
              <MenuItem value="convite_feira_abimad">
                Convite para Feira ABIMAD 2025
              </MenuItem>
              <MenuItem value="atendimento_inbound">
                Atendimento Inbound (simulação)
              </MenuItem>
            </Select>
            {(callType === "clientes_inativos" ||
              callType === "convite_feira_abimad") && (
              <>
                <TextField
                  label="Nome cliente"
                  variant="filled"
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  fullWidth
                  margin="normal"
                  value={userFirstName}
                  onChange={(e) => setUserFirstName(e.target.value)}
                  required
                />
              </>
            )}
            {callType === "convite_feira_abimad" && <></>}
            {callType === "atendimento_inbound" && <></>}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                    required
                  >
                    <MenuItem value="us">🇺🇸 (+1)</MenuItem>
                    <MenuItem value="br">🇧🇷 (+55)</MenuItem>
                    <MenuItem value="gb">🇬🇧 (+44)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={"100%"}>
                  <br />
                  <PhoneInput
                    country={country}
                    value={phoneNumber}
                    disableDropdown={true}
                    disableCountryGuess={true}
                    onChange={setPhoneNumber}
                    enableLongNumbers={true}
                    inputStyle={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "4px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>
            <br />
            <h3>
              Depois de clicar para receber a chamada da DEMO, você receberá uma
              chamada do seguinte número de telefone: <b>+1 (548) 918-5087</b>
            </h3>
            <br />
            <Button
              className="callBtn"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              <h2
                style={{
                  fontSize: "16px",
                }}
              >
                Clique aqui para receber a chamada DEMO
              </h2>
              <div style={{ width: "20px" }} />
              <Call className="callIcon" />
            </Button>
          </form>
        </Container>
      </Grid>
    </div>
  );
};
